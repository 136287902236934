import {
  LineItem,
  LineItemCreate,
} from '@commercelayer/sdk/lib/resources/line_items';
import { Order } from '@commercelayer/sdk/lib/resources/orders';
import { Shipment } from '@commercelayer/sdk/lib/resources/shipments';
import axios from 'axios';

import { IAttributionParams } from 'checkout/types/checkout';
import { IProductType } from 'productSelection/types/products';
import EcomClient from 'shared/infra/ecom/client';
import { ITrackingParams } from 'shared/services/attribution';

export interface IOrderMetadata extends IAttributionParams, ITrackingParams {
  business_account_offer_available?: true;
}

// TODO: Remove global type upstream
const addressTypeMap = {
  shippingAddress: 'shipping_address',
  billingAddress: 'billing_address',
};

export const getOrder = async (orderId: string): Promise<Order> => {
  const ecom = await EcomClient.getClient();

  return ecom.orders.retrieve(orderId, {
    include: [
      'line_items',
      'shipping_address',
      'billing_address',
      'shipments',
      'payment_method',
    ],
  });
};

export const createOrder = async (
  couponCode?: string,
  languageCode?: string,
  attributionParams?: IAttributionParams,
  trackingParams?: ITrackingParams,
  isBusinessAccountBundleEnabled?: boolean,
): Promise<Order> => {
  /**
   * We need to wait for the client initialization here to ensure that the
   * authentication is automatically refreshed once the token expires.
   */
  const accessToken = await EcomClient.getAccessToken();

  const metadata: IOrderMetadata = { ...attributionParams, ...trackingParams };

  if (isBusinessAccountBundleEnabled) {
    metadata.business_account_offer_available = true;
  }

  return axios
    .post(
      '/api/order/create',
      { couponCode, languageCode, metadata },
      {
        headers: {
          'x-commercelayer-authorization': `Bearer ${accessToken}`,
        },
      },
    )
    .then((response) => response.data)
    .catch((error) => error);
};

export const addProduct = async (
  orderId: string,
  skuCode: string,
  type: IProductType,
  quantity = 1,
  reference?: string,
  localizedName?: string,
): Promise<LineItem> => {
  const ecom = await EcomClient.getClient();
  const payload: LineItemCreate = {
    quantity,
    reference: reference || null,
    metadata: {
      "localized_name": localizedName,
    },
    order: ecom.orders.relationship(orderId),
  };

  if (type === 'sku') {
    payload.sku_code = skuCode;
  }

  if (type === 'bundle') {
    payload.bundle_code = skuCode;
  }

  return ecom.line_items.create(payload);
};

export const removeProduct = async (id: string): Promise<void> => {
  const ecom = await EcomClient.getClient();
  return ecom.line_items.delete(id);
};

export const updateOrder = async (
  orderId: string,
  updateDetails: Record<string, unknown>,
): Promise<Order> => {
  const ecom = await EcomClient.getClient();
  return ecom.orders.update({ id: orderId, ...updateDetails });
};

export const addPaymentMethod = async (
  orderId: string,
  paymentMethodId: string,
): Promise<Order> => {
  const ecom = await EcomClient.getClient();

  return ecom.orders.update({
    id: orderId,
    payment_method: ecom.payment_methods.relationship(paymentMethodId),
  });
};

export const addShippingMethod = async (
  shipmentID: string,
  shippingMethodID: string,
): Promise<Shipment> => {
  const ecom = await EcomClient.getClient();

  return ecom.shipments.update({
    id: shipmentID,
    shipping_method: ecom.shipping_methods.relationship(shippingMethodID),
  });
};

export const addAddressToOrder = async (
  orderID: string,
  addressId: string,
  addressType: string,
): Promise<Order> => {
  const ecom = await EcomClient.getClient();

  return ecom.orders.update({
    id: orderID,
    [addressTypeMap[addressType]]: ecom.addresses.relationship(addressId),
    _update_taxes: true,
  });
};

export const updateLineItems = async (
  lineItemId: string,
  updateDetails: Record<string, unknown>,
): Promise<LineItem> => {
  const ecom = await EcomClient.getClient();

  return ecom.line_items.update({
    id: lineItemId,
    ...updateDetails,
  });
};

export const removeLineItems = async (lineItemId: string): Promise<void> => {
  const ecom = await EcomClient.getClient();

  return ecom.line_items.delete(lineItemId);
};

export const updateTaxes = async (orderID: string): Promise<void> => {
  const ecom = await EcomClient.getClient();

  await ecom.orders.update({
    id: orderID,
    _update_taxes: true,
  });
};

export const getBankwirePurpose = async (
  orderId: string,
): Promise<string | null> => {
  const ecom = await EcomClient.getClient();

  const order = await ecom.orders.retrieve(orderId, {
    include: ['payment_source'],
  });

  if (!order.payment_source?.metadata) {
    const errorMessage = `No Payment Source metadata found for order ${orderId}, bankwire details will not be displayed`;
    throw new Error(errorMessage);
  } else {
    const purpose = order.payment_source.metadata?.bankwire_purpose as string;
    if (typeof purpose === 'string') {
      return purpose;
    }

    return null;
  }
};

export const addCoupon = async (
  orderID: string,
  code: string,
): Promise<Order> => {
  const ecom = await EcomClient.getClient();
  // TODO: add includes
  return ecom.orders.update({
    id: orderID,
    coupon_code: code,
    _update_taxes: true,
  });
};

export const removeCoupon = async (
  orderId: string,
): Promise<ReturnType<typeof updateOrder> | void> =>
  updateOrder(orderId, { coupon_code: null });

export const getStockItems = async (skus: string[]) => {
  const ecom = await EcomClient.getClient();
  return ecom.stock_items.list({
    filters: { sku_code_in: skus.join(',') },
  });
};
